.govuk-template {
  &__body {
    main {
      position: relative;
    }
    .govuk-hint {
      margin-bottom: 0;
      font-size: 14px;
      text-indent: 12px;
      padding-top: 3px;
      @media screen and (max-width: 1279px) {
        margin-bottom: 10px;
      }
    }
    .govuk-header {
      border-bottom: 10px solid $govuk-brand-colour;
      &__link {
        &.active {
          text-decoration: underline;
        }
      }
      &__logotype {
        &--border {
          border-color: govuk-colour("white");
        }
      }
    }
    .govuk-form-group {
      margin-bottom: 0;
    }
    .govuk-button {
      &--grey {
        background-color: govuk-colour("mid-grey");
        color: govuk-colour("white");
      }
    }
    .govuk-sidebar {
      &__closed {
        background-color: govuk-colour("white");
      }
      &__collapse {
        border-bottom: 1px solid $govuk-border-colour;
      }
    }
    .govuk-image {
      width: 100%;
      height: auto;
    }
  }
}
