// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

// Plus imports for other components in your app.

@import "~normalize.css";

@import "~@angular/material/theming";
@import "./assets/scss/palette";

$dcms-lottery-typography: mat-typography-config(
  $font-family: "GDS Transport, arial, sans-seri",
  $headline: mat-typography-level(32px, 48px, 700),
  $body-1: mat-typography-level(16px, 24px, 500),
);

@include mat-base-typography($dcms-lottery-typography);

@include angular-material-typography($dcms-lottery-typography);

@include mat-core($dcms-lottery-typography);

$dcms-lottery-primary: mat-palette($dcms-palette);
$dcms-lottery-accent: mat-palette($mat-pink, A200, A100, A400);

$dcms-lottery-warn: mat-palette($mat-red);

$dcms-lottery-theme: mat-light-theme(
  (
    color: (
      primary: $dcms-lottery-primary,
      accent: $dcms-lottery-accent,
      warn: $dcms-lottery-warn,
    ),
  )
);

@include angular-material-theme($dcms-lottery-theme);

@import "~govuk-frontend/govuk/all";
@import "./assets/scss/material";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "./assets/scss/loader";
@import "./assets/scss/govuk";
@import "./assets/scss/charts";
@import "./assets/scss/responsive";

html,
body {
  height: 100%;
  box-sizing: border-box;
  margin: 0;
  font-family: "GDS Transport", arial, sans-serif;
}

.hide {display: none;}
.filter-open {
  overflow: hidden;
}
.govuk-template {
  &__body {
    .filters-wrapper {
      background-color: govuk-colour("white");
      .mat-form-field-wrapper {
        padding-bottom: 0;
      }
      .mat-select-arrow-wrapper {
        vertical-align: bottom;
      }
    }
    .hidden {
      display: none;
    }

    .filters-wrapper {
      .mat-chip-list-wrapper {
        display: block;
      }
    }
    .govuk-grants {
      .govuk-form-group {
        margin-top: 20px;
        .mat-form-field-wrapper {
          padding-bottom: 0;
        }
      }
      .mat-form-field {
        background-color: govuk-colour("white");
      }
      .mat-form-field-appearance-outline .mat-form-field-suffix {
        top: 9px;
      }
    }

    .cc-revoke {
      display: none;
    }

    .cc-btn.cc-govuk-button,
    .cc-highlight .cc-btn:first-child {
      background-color: #00703c;
      box-shadow: 0 2px 0 #002d18;
      color: #ffffff;
      text-decoration: none;
      padding: 8px 10px 7px;
      border: 2px solid transparent;
      &:hover {
        background-color: #005a30;
        text-decoration: none;
      }
      &:focus {
        border-color: #fd0;
        color: #0b0c0c;
        background-color: #fd0;
        box-shadow: 0 2px 0 #0b0c0c;
      }
      &.cc-allow {
        line-height: 1.5em;
      }
    }
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  .loading:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #000000;
    animation: spinner 0.8s linear infinite;
  }

  .internal-server-error,
  .page-not-found {
    text-align: center;
    height: 100%;
    min-height: 550px;
  }

  .mapInfo {
    top: -10px;
    background: rgba(255, 255, 255, 0.9);
    //max-width: 500px;
    border: 1px solid black;
    width: 200px;
    border-radius: 0px;
    padding: 10px;
    @media screen and (max-width: 400px) {
      width: 180px;
    }
  }

  .leaflet-popup-close-button {
    color: black;
    padding: 0;
    min-width: 20px;
    background-color: white;
    height: 20px;
    width: 20px;
    font: 16px/14px Tahoma, Verdana, sans-serif;
    font-weight: bold;
    border: 1px #ccc solid;
    &:hover {
      color: black;
      cursor: pointer;
      border: 1px black solid;
    }
    &:focus {
      color: black;
      cursor: pointer;
    }
  }

  .leaflet-container :focus {
    background-color: #ffdd00 !important;
    outline: -webkit-focus-ring-color auto thin !important; /* Fallback for some browsers that don't support `revert`. */
    /* Longhand properties:
  outline-color: -webkit-focus-ring-color !important;
  outline-style: auto !important;
  outline-width: thin !important;
  */
  }


.leaflet-container{
  .search-input {
    background-color: white;
    .mat-form-field-wrapper {
      padding-bottom: 0px;
      @media screen and (max-width: 400px) {
        width: 200px;
      }
    }
    input{
      font-size: 14px;
    }
    input:focus{
      background-color: white !important;
      outline: none !important;
    }
  }
}
  .leaflet-container .leaflet-overlay-pane :focus {
    background-color: transparent !important;
    outline: revert !important;
  }

  .leaflet-top,
  .leaflet-bottom {
      pointer-events: all;
  }

  .leaflet-tooltip.map-city-label {
    background-color: transparent;
    box-shadow: unset;
    padding: 0px;
    font-size: 15px;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    //text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    border: transparent;
  }
}

.logo-lottery {
  max-height: 4rem;
}

.logo-department {
  max-height: 6rem;
}

// Cookies
.cc-window.cc-color-override--1133223822 {
  display: none;
}

#ccc-optional-categories {
  display: none;
}