.govuk-template {
  &__body {
    .mat-chip-list-wrapper input.mat-input-element {
      width: 95%;
    }
    .mat-form-field-appearance-outline .mat-form-field-infix {
      padding: 1.2em 0 1em 0;
      border-top: 0;
    }
    .mat-form-field-appearance-outline .mat-form-field-outline-start {
      border-radius: 0;
      border-right-style: none;
    }
    .mat-form-field-appearance-outline .mat-form-field-prefix,
    .mat-form-field-appearance-outline .mat-form-field-suffix {
      margin-top: 0.45em;
    }
    .mat-form-field-appearance-outline .mat-form-field-outline-gap {
      border-radius: 0.000001px;
      border: 1px solid currentColor;
      border-left-style: none;
      border-right-style: none;
    }
    .mat-form-field-appearance-outline .mat-form-field-outline-end {
      border-radius: 0;
      border-left-style: none;
      flex-grow: 1;
    }
    .mat-form-field-appearance-outline .mat-form-field-wrapper {
      margin: 0;
    }
    .mat-form-field-appearance-outline .mat-form-field-outline-start,
    .mat-form-field-appearance-outline .mat-form-field-outline-end {
      border-width: 2px;
    }
    .mat-input-element::placeholder,
    .mat-select-placeholder {
      color: #505a5f;
    }

    .mat-focused {
      outline: 3px solid govuk-colour("yellow");
      outline-offset: 0;
      -webkit-box-shadow: inset 0 0 0 2px;
      box-shadow: inset 0 0 0 2px;
    }
    .mat-drawer-content {
      overflow-x: hidden;
    }
    .mat-cell,
    .mat-footer-cell {
      font-size: 14px;
      padding: 3px;
    }
    tr.mat-row,
    tr.mat-footer-row {
      height: 38px;
    }
    mat-form-field {
      &.has-arrow {
        &:after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid;
          border-top-color: rgba(0, 0, 0, 0.54);
          top: 23px;
          right: 17px;
          pointer-events: none;
        }
      }
    }
    mat-paginator {
      font-size: 14px;
      color: black;
      .mat-paginator-page-size .mat-select-trigger{
        font-size: 14px;
      }
      .mat-paginator-page-size-select {
        padding: 3px;
      }
      .mat-paginator-range-actions {
        button {
          border: 2px solid white;
          mat-icon-button {
            line-height: 30px;
          }
         
          &:hover &:not([disabled]) {
            border: 2px solid black;
          }
          &:focus {
            box-shadow: 0 0 0 2px govuk-colour("yellow");
            border: 2px solid black;
          }
        }
      }
    }
    .mat-sort-header-container {
      .mat-sort-header-content {
        color: black;
        text-align: left;
        font-weight: 600;
        font-size: 14px;
      }
      &:focus {
        background-color: #ffdd00;
      }
    }
    // .mat-select-panel{
    //   max-height: 256px;
    //   @media screen and (max-width: 600px) {
    //     max-height: 200px;
    //   }
    // }
    .good-cause-panel {
      min-width: calc(100% + 39px) !important;
    }

    .mat-datepicker-toggle {
      button {
        &:hover {
          border: 2px solid black;
        }
        &:focus {
          background: white;
          box-shadow: 0 0 0 3px govuk-colour("yellow");
          border: 2px solid black;
        }
      }
    }
  }
}
