.govuk-template {
  &__body {
    .ngx-charts{
      float: unset;
    }
    /* Makes the chart container expand to the full width of the parent container*/
    .ngx-charts-outer {
      width: 100% !important;
      svg{
        display: block;
        margin: auto;
      }
      .pie-label {
        font-size: 14px;
      }
      .bar-chart {
        text {
          font-size: 14px !important;
        }
      }

      .ngx-charts .arc{
        stroke: white;
        stroke-width: 2px;
      }
      
    }

    .chart-legend .legend-labels {
      width: 200px;
     li{ display: block;}
    }

    .chart-legend .legend-label-text {
      white-space: normal;
      font-size: 14px;
      color: #505a5f;
    }
  }

  .toggle-button {
    border: 1px solid #b1b4b6;
    color: #1d70b8;
    cursor: pointer;
    margin: 0;
    padding: 10px;
    background-color: #fff;
    &:focus {
      outline: 3px solid transparent;
      color: #0b0c0c;
      background-color: #fd0;
      box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
      text-decoration: none;
      background-color: #fd0;
      border-color: transparent;
    }
  }
  table.charts {
    margin: 30px 0;
    border-collapse: collapse;
    overflow-x: auto;
    display: block;

    th {
      text-align: left;
      padding: 10px 10px 10px 0;
    }
    td {
      border-bottom: solid 1px #b1b4b6;
      padding: 10px 10px 10px 0;
    }
  }

  .ngx-charts-tooltip-content.animate{    
    pointer-events: auto!important;
    .tooltip-caret{
      display: none;
    }
  }
}
